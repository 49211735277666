<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Penjadwalan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      {
        width: "50px",
        label: "No",
        name: "-",
      },
      {
        width: "auto",
        label: "ID Jadwal",
        name: "idjadwal",
      },
      {
        width: "auto",
        label: "batch",
        name: "batch",
      },
      {
        width: "auto",
        label: "Tanggal Batch",
        name: "tanggal_batch",
      },
      {
        width: "auto",
        label: "NIP",
        name: "nip",
      },
      {
        width: "auto",
        label: "Nama",
        name: "nama",
      },
      {
        width: "auto",
        label: "Jabatan",
        name: "jabatan",
      },
      {
        width: "auto",
        label: "Kompetensi",
        name: "kompetensi",
      },
      {
        width: "auto",
        label: "Level",
        name: "level",
      },
      {
        width: "auto",
        label: "Tanggal Mengerjakan",
        name: "level",
      },
      {
        width: "auto",
        label: "Status",
        name: "status",
      },
      {
        width: "auto",
        label: "Nilai",
        name: "nilai",
      },
      {
        width: "auto",
        label: "Hasil",
        name: "hasil",
      },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Report Nilai Kompetensi",
      isWrite: localStorage.getItem("isWrite"),
      isRead: localStorage.getItem("isRead"),
      showModalAdd: false,
      showModalEdit: false,
      selected_id: "ss",
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_status: "all", // untuk filtering data
        idjadwal: "",
        jabatan: "",
        idbatch: "",
        nama: "",
        nip: "",
      },

      selected_id_jadwal: "",
      selected_batch: "",
      selected_jabatan: "",

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      option_jadwal: [],
      option_jabatan: [],
      option_batch: [],
    };
  },
  mounted() {
    let self = this;
    self.getDataTable();
    self.searchJadwal(true, 0);
    this.searchJabatan(true, "");
  },
  methods: {
    onSearchJadwal(search, loading) {
      if (search.length) {
        loading(true);
        this.searchJadwal(loading, search, this);
      }
    },
    searchJadwal(loading, search) {
      const authToken = localStorage.access_token;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `referensi/search-jadwal?keyword=${search}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.option_jadwal = response.data.data.referensi;

          loading(false);
        })
        .catch(() => {});
      console.log();
    },
    onSearchJabatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchJabatan(loading, search, this);
      }
    },
    searchJabatan(loading, search) {
      const authToken = localStorage.access_token;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `referensi/search-jabatan?keyword=${search}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.option_jabatan = response.data.data.referensi;

          loading(false);
        })
        .catch(() => {});
      console.log();
    },
    onSearchBatch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchBatch(loading, search, this);
      }
    },
    searchBatch(loading, search) {
      const authToken = localStorage.access_token;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `referensi/search-batch?keyword=${search}&idjadwal=${this.tableData.idjadwal}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.option_batch = response.data.data.referensi;

          loading(false);
        })
        .catch(() => {});
      console.log();
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "report-nilai-kompetensi"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            console.log(response_data_fix);
            self.table_data = response_data_fix.data;
            self.configPagination(response_data_fix);
            self.currentTablePage = response_data_fix.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    changeStatusData(status, id) {
      let self = this;

      let text_alert = "";
      if (status == "ENABLE") {
        text_alert = "Apakah anda yakin ingin mengaktifkan data tersebut?";
      } else {
        text_alert = "Apakah anda yakin ingin menonaktifkan data tersebut?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/user",
            data: {
              id: id,
              status: status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/jadwal-pelaksanaan/" +
              id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },

    onChangeJadwal() {
      this.tableData.idjadwal = this.selected_id_jadwal.idjadwal;
      this.searchBatch(true, "");
    },

    onChangeBatch() {
      this.tableData.idbatch = this.selected_batch.idjadwal_batch;
    },
    onChangeJabatan() {
      this.tableData.jabatan = this.selected_jabatan.nama_jabatan;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Report Nilai Kompetensi</div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-2">
                    <label for="statusTable">Jadwal</label>
                    <v-select
                      :options="option_jadwal"
                      v-model="selected_id_jadwal"
                      label="idjadwal"
                      placeholder="Pilih ID Jadwal"
                      @search="onSearchJadwal"
                      v-on:update:modelValue="onChangeJadwal"
                    >
                    </v-select>
                  </div>
                  <div class="form-group col-md-2" v-if="tableData.idjadwal">
                    <label for="statusTable">Batch</label>
                    <v-select
                      :options="option_batch"
                      v-model="selected_batch"
                      label="nama_batch"
                      placeholder="Pilih Batch"
                      @search="onSearchBatch"
                      v-on:update:modelValue="onChangeBatch"
                    >
                    </v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="statusTable">Jabatan</label>
                    <v-select
                      :options="option_jabatan"
                      v-model="selected_jabatan"
                      label="nama_jabatan"
                      placeholder="Pilih Jabatan"
                      @search="onSearchJabatan"
                      v-on:update:modelValue="onChangeJabatan"
                    >
                    </v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="statusTable">Nama</label>
                    <input
                      type="text"
                      class="form-control"
                      id="tanggal_selesai"
                      v-model="tableData.nama"
                      required
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="statusTable">Nip</label>
                    <input
                      type="nip"
                      class="form-control"
                      id="tanggal_selesai"
                      v-model="tableData.nip"
                      required
                    />
                  </div>
                  <div class="p-2 col-md-2 mt-3">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="getDataTable()"
                    >
                      Filter
                    </button>
                  </div>
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableData.length"
                            @change="getDataTable()"
                          >
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="getDataTable()"
                    />
                  </div>
                </div>
              </div>
              <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="6">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="table_data == ''">
                    <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(row_data, key_data) in table_data"
                    :key="key_data"
                  >
                    <td class="text-center">
                      <div v-if="key_data + 1 == 10">
                        {{ currentTablePage }}0.
                      </div>
                      <div v-else>
                        {{
                          currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                        }}{{ key_data + 1 }}.
                      </div>
                    </td>
                    <td>
                      {{ row_data.idjadwal }}
                    </td>
                    <td>
                      {{ row_data.nama_batch }}
                    </td>
                    <td>
                      {{ row_data.waktu_mulai }}
                    </td>
                    <td>
                      {{ row_data.nip }}
                    </td>
                    <td>
                      {{ row_data.nama }}
                    </td>
                    <td>
                      {{ row_data.jabatan_nama }}
                    </td>
                    <td>
                      {{ row_data.nama_kompetensi }}
                    </td>
                    <td>
                      {{ row_data.nama_level }}
                    </td>
                    <td>
                      {{ row_data.created_at ?? "-" }}
                    </td>
                    <td>
                      {{ row_data.status ?? "-" }}
                    </td>
                    <td>
                      {{ row_data.prosentase_skor ?? "-" }}
                    </td>
                    <td>
                      {{ row_data.nilai_text }}
                    </td>
                  </tr>
                </tbody>
              </datatable>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
